import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.min.css";

import ParamedicalHome from "./components/Paramedical/ParamedicalHome";

import Leadership from "./components/Leadership/Leadership";
import LandingPage from "./components/LandingPage";
import NizamudinHome from "./components/Nurse Training/NurseHome";
import Abouthome from "./components/About/Abouthome";
import Pagecontacthome from "./components/PageContact/Pagecontacthome";

import ScrollToTop from "./components/Scrooltotop";
import Donor from "./components/Donor/Donor";
import Donorhome from "./components/Donor/Donorhome";
import Facultyhome from "./components/Faculty/Facultyhome";
import Classroom from "./components/infra/Pages/Classroom";
import { alumniData } from "./components/Nurse Training/Data";
import { classroomData } from "./components/infra/infracarddata";
import { computerData } from "./components/infra/infracarddata";
import Safety from "./components/infra/Pages/Safety";
import { internetData } from "./components/infra/infracarddata";
import { extraCurricularData } from "./components/infra/infracarddata";
import Otherfacilities from "./components/infra/Pages/Otherfacilities";
import Inspired from "./components/Inspired/Inspired";
import { raipurAlumniData } from "./components/Paramedical/RaipurcardData";
import FeeStructure from "./components/Fee Structure/FeeStructure";
import {
  classroomImg,
  labImg,
  internetImg,
} from "./components/infra/infracarddata";
import ComputerLabs from "./components/infra/Pages/ComputerLabs";
import Academics from "./components/Academics/Academics";
import AcademicHome from "./components/Academics/AcademicHome";
import ExtraCurricular from "./components/infra/Pages/ExtraCurricular";
import Login from "./components/Home/Login";
import StudentTCTable from "./components/table/StudentTCTable";
import { NewRegistration } from "./components/newRegistration/NewRegistration";
import { ExistingFees } from "./components/ExistingFees/ExistingFees";
import Privacy from "./components/privacy/Privacy";
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/student-tc" element={<StudentTCTable />} />
          <Route path="/new-registration" element={<NewRegistration />} />
          <Route path="/existing-fees" element={<ExistingFees />} />
          <Route path="/academics" element={<AcademicHome />} />
          <Route path="/messsge" element={<Leadership />} />

          <Route path="/nursingcourses" element={<NizamudinHome />} />
          <Route path="/paramedicalcourse" element={<ParamedicalHome />} />

          <Route path="/about" element={<Abouthome />} />
          <Route path="/pagecontact" element={<Pagecontacthome />} />

          <Route path="/donors" element={<Donorhome />} />
          <Route path="/faculty" element={<Facultyhome />} />
          <Route
            path="/classrooms"
            element={<Classroom data={classroomData} imgData={classroomImg} />}
          />
          <Route path="/computerlabs" element={<ComputerLabs />} />
          <Route
            path="/internet"
            element={<Classroom data={internetData} imgData={internetImg} />}
          />
          <Route
            path="/extra"
            element={
              <ExtraCurricular
                data={extraCurricularData}
                imgData={internetImg}
              />
            }
          />
          <Route path="/safety" element={<Safety />} />
          <Route path="/otherfacilites" element={<Otherfacilities />} />
          <Route
            path="/nizamalumni"
            element={<Inspired alumniData={alumniData} />}
          />
          <Route
            path="/raipuralumni"
            element={<Inspired alumniData={raipurAlumniData} />}
          />
          <Route path="/privacy" element={<Privacy />} />
          {/* <Route path="/student-login" element={<StudentLogin />} /> */}
         <Route path="/fees" element={<FeeStructure />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
