import "./home.css";

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";
const Home = (props) => {
  return (
    <>
      {/* <div className="image-slider">
                   <center>
                     <div className="bottomleft box">
                        <p className="h">{props.data}</p>
                    </div>
                    </center>

                </div> */}

      {/* 
            <section className="home" id="home">



            

                <div className="banner1">

                    <img   src="./images/Landingslide/Nizam.jpg" alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute, Nizamuddin</p>
                    </div>
                    </center>



                </div>
                <div className="banner2">

                    <img  src="./images/Landingslide/raipur.jpg" alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute,Raipur</p>
                    </div>
                    </center>



                </div>
                <div className="banner3">

                    <img  src="./images/Landingslide/Bhakatpara.jpg"  alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute, Bhakatpara</p>
                    </div>
                    </center>



                </div>
                <div className="banner4">

                    <img    src="./images/Landingslide/school.jpeg" alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute, School</p>
                    </div>
                    </center>



                </div>
                <div className="banner5">

                    <img  className='homeimg' src="./images/Landingslide/Nizam2.jpg" alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute,Nizamuddin</p>
                    </div>
                    </center>



                </div>
                <div className="banner6">

                    <img  className='homeimg' src="./images/Landingslide/raipur2.jpg"  alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute,Raipur</p>
                    </div>
                    </center>



                </div>
                <div className="banner7">

                    <img className='homeimg' src="./images/Landingslide/bhakatpara2.jpg" alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute, Bhakatpara</p>
                    </div>
                    </center>



                </div>
                <div className="banner8">

                    <img className='homeimg' src="./images/Landingslide/school2.jpeg"  alt="" />

                    <center>
                    <div className="bottomleft box">
                        <p className="h">Hermann Gmeiner Vocational Training Institute, School</p>
                    </div>
                    </center>



                </div>


            </section> */}

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="./images/Landingslide/s1.jpeg" alt="" />
          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Nizamuddin</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img src="./images/Landingslide/s2.jpg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Raipur</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img src="./images/Landingslide/s3.jpeg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Bhakatpara</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img src="./images/Landingslide/s4.jpeg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner After School Vocational Training Institute</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="homeimg" src="./images/Landingslide/s5.jpeg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Nizamuddin</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="homeimg" src="./images/Landingslide/s6.jpg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Raipur</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img className="homeimg" src="./images/Landingslide/s7.jpeg" alt="" />
          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner Vocational Training Institute, Bhakatpara</p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="homeimg" src="./images/Landingslide/s8.jpeg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner After School Vocational Training Institute </p>
                        </div> */}
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <img className="homeimg" src="./images/Landingslide/s9.jpeg" alt="" />

          <center>
            {/* <div className="bottomleft box">
                            <p className="h">Hermann Gmeiner After School Vocational Training Institute </p>
                        </div> */}
          </center>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Home;
