import React from 'react'
import { Link } from 'react-router-dom'
const Alumni = ({link}) => {
  return (
    <>

      <section className="alumni my-4">
        <div className=' container-fluid py-2 banner' id='banner_sec'>


          <p id='aluhead'>Illustrious Alumni</p>
          <h2 id='aluhead2'>Get inspired by the success stories of our notable alumni!</h2>
          <div className="inspired_button" id='ibtn'>
            <Link className='a' to={link}>

              <button className='btn read-more' id='read-more'>Read More</button>

            </Link>
          </div>

        </div>
      </section>

    </>
  )
}

export default Alumni
