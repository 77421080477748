import React, { useState } from "react";
import "./pagecontact.css";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const url = "https://sos12.herokuapp.com/form-submit-nursing";
const Pagecontact = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    Username: "",
    Email: "",
    Mobile: "",
    City: "",
    Course: "",
    Message: "",
  });

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(newdata);
  }
  function submit(e) {
    e.preventDefault();
    setLoading(true);
    Axios.post(url, {
      username: data.Username,
      email: data.Email,
      mobile: data.Mobile,
      city: data.City,
      grade: data.Grade,
      message: data.Message,
      school: data.school,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === "Success") {
          setSuccess(true);
          setLoading(false);
          toast.success("Your response sent successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setData({
            Username: "",
            Email: "",
            Mobile: "",
            City: "",
            Grade: "",
            Message: "",
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("something went wrong!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }
  return (
    <>
      <div className="contactus_main">
        {success ? (
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        ) : (
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        )}
        <div className="contactheading">
          <div className="con_heading">
            <h2>We'd love to hear from you.</h2>
          </div>
        </div>

        <div className="contactinfo">
          <div>
            <div className="fc">
              <div className="ist">Address</div>
              <div className="znd">SOS NURSING SCHOOL</div>
              <div className="trd">
                Hermann Gmeiner Medical & Social Campus,
              </div>
              <div className="trd">Village Anangpur, Faridabad (Hr) 121003</div>
            </div>
            <div className="fc2">
              <div className="ist">Contact Person:</div>
              <div className="znd">Raj Singh</div>
            </div>
            <div className="fc2">
              <div className="ist">Phone:</div>
              <div className="znd">0129-2500355</div>
            </div>
            <div className="fc2">
              <div className="ist">Mobile:</div>
              <div className="znd">7703932712</div>
            </div>
            <div className="fc2">
              <div className="ist">Email:</div>
              <div className="znd">
                principal.nursing@hermann-gmeiner-edu.org
              </div>
            </div>
            <div className="fc">
              <div className="ist">Socials</div>

              <div className="detail">
                <div className="profiles">
                  <div className="profile">
                    <a
                      href="https://www.facebook.com/GNMANM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="fab fa-facebook fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={(e) => submit(e)}>
            <div className="contactform">
              <div className="form-main">
                <div className="input-container">
                  <span className="">
                    <i class="far fa-user icon  fa-2x"></i>
                  </span>

                  <input
                    onChange={(e) => handle(e)}
                    id="Username"
                    value={data.Username}
                    class="forminput"
                    type="text"
                    required
                    placeholder="Full Name"
                    name="Username"
                  />
                </div>
                <div className="input-container">
                  <span className="">
                    <i class="far fa-envelope icon fa-2x"></i>
                  </span>

                  <input
                    onChange={(e) => handle(e)}
                    id="Email"
                    required
                    value={data.Email}
                    class="forminput"
                    type="text"
                    placeholder="Email"
                    name="Email"
                  />
                </div>
                <div className="input-container">
                  <span className="">
                    <i class="fa fa-mobile fa-2x icon"></i>
                  </span>

                  <input
                    onChange={(e) => handle(e)}
                    id="Mobile"
                    value={data.Mobile}
                    required
                    class="forminput"
                    type="text"
                    placeholder="Mobile"
                    name="Mobile"
                  />
                </div>
                <div className="input-container">
                  <span className="">
                    <i class="fa fa-city  fa-2x icon"></i>
                  </span>
                  <input
                    onChange={(e) => handle(e)}
                    id="City"
                    required
                    value={data.City}
                    class="forminput"
                    type="text"
                    placeholder="City*"
                    name="City"
                  />
                </div>
                <div className="input-container">
                  <span className="">
                    <i class="fa fa-user-graduate icon fa-2x"></i>
                  </span>
                  <input
                    onChange={(e) => handle(e)}
                    id="Course"
                    required
                    value={data.Course}
                    class="forminput"
                    type="text"
                    placeholder="Course"
                    name="Course"
                  />
                </div>
                <div className="input-container">
                  <span className="">
                    <i class="far fa-envelope icon fa-2x"></i>
                  </span>

                  <input
                    onChange={(e) => handle(e)}
                    id="Message"
                    value={data.Message}
                    class="forminput"
                    required
                    type="text"
                    placeholder="Message"
                    name="Message"
                  />
                </div>

                <div className="formconbtn">
                  <button disabled={loading} className="btn-submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Pagecontact;
