import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import physicalCover from "../../images/covers/physicalCover.jpeg";
import { NavLink } from "react-router-dom";


const Footer = () => {
  return (
    <>
      {/* <footer>

        <div class="container-fluid content py-5">

          <div class="aboutus">

            <img src="./images/footerimg/1.png" className="footimg" alt="Footer img" />

          </div>

          <div class=" quicklinks">

            <h2>Quick Links</h2>

            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">VTI Nizamudin</a></li>
              <li><a href="#">VTI Raipur</a></li>
            </ul>
          </div>


          <div class="contact">


            <div className="flogo">

              <img src="./images/logo.svg" alt="" />
            </div>

            <ul>
              <h6>Contact us</h6>
              <li><a href="#">VTI Bhakatpara</a></li>
              <li><a href="#">VTI After School</a></li>
            </ul>
          </div>






        </div>

        
   
        
        <div class="copyright">

          <p>Copyright &copy; 2022 All rights reserved</p>
        </div>
      </footer> */}

      <footer className="text-center" style={{ background: "#F8F9F9" }}>
        <section className="container-fluid mt-4 mb-4">
          <div className="row d-flex justify-content-start">
            <div className="col-lg-3 d-flex justify-content-center">
              <center className="align-image">
                <NavLink to="/">
                  <img className="logo1" src="./images/logo.svg" alt="" />
                </NavLink>
              </center>
            </div>

            <div className="col-lg-3 ">
              <div class=" quicklinks">
                <h2>Quick Links</h2>

                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <a href="/academics">Activites</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3">
              <div class="contact d-flex justify-content-end">
                {/* <div className="flogo">
                  <img src="./images/logo.svg" alt="" />
                </div> */}

                <ul>
                  <li>
                    <Link to="/nursingcourses">Nursing Courses</Link>
                  </li>

                  <li>
                    <a href="/faculty">Faculty</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div class="contact d-flex justify-content-end">
                {/* <div className="flogo">
                  <img src="./images/logo.svg" alt="" />
                </div> */}

                <ul>
                  <li>
                    <Link to="/paramedicalcourse">Paramedical Courses</Link>
                  </li>
                  <li>
                    <Link to="/pagecontact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div class="copyright">
          <p>Copyright &copy; 2022 All rights reserved</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
