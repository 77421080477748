import React from "react";

import NurseCoursedata from "./NurseCoursedata";

import Alumni from "./Alumni";
import Nurseplacement from "./Nurseplacement";

import Footer from "../footer/Footer";

import Navbar from "../navbar/Navbar";

import imgData from "./ImageData";
import Nursecard from "./Nursecard";
import Homesliding from "./Homesliding";
import LandingImage from "../LandingImage";
import nusring from "../../images/covers/nursing.jpeg";
const NurseHome = () => {
  const data = {
    text: `Nursing is a profession within the health care sector focused on the care of individuals, families, and communities so they may attain, maintain, or recover optimal health and quality of life. Nurses may be differentiated from other health care providers by their approach to patient care, training, and scope of practice. Nurses practice in many specialties with differing levels of prescription authority.  Many nurses provide care within the ordering scope of physicians, and this traditional role has shaped the public image of nurses as care providers. 
 
    Nurses develop a plan of care, working collaboratively with physicians, therapists, the patient, the patient's family, and other team members that focuses on treating illness to improve quality of life. Nurses may help coordinate the patient care performed by other members of a multidisciplinary health care team such as therapists, medical practitioners, and dietitians. Nurses provide care both interdependently, for example, with physicians, and independently as nursing professionals. In addition to providing care and support, nurses educate the public, and promote health and wellness.`,
  };
  return (
    <>
      <Navbar />
      <LandingImage cover={nusring} />
      <NurseCoursedata data={data} />
      <Nursecard />
      <Nurseplacement imgData={imgData} />
      <Alumni link="/nizamalumni" />

      <div style={{ background: "#f4f6f6" }}>
        {" "}
        <Footer />
      </div>
    </>
  );
};

export default NurseHome;
