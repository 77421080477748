import al1 from "../../images/alumImg/al1.png";
import al2 from "../../images/alumImg/al2.png";
import al3 from "../../images/alumImg/al3.png";
import al4 from "../../images/alumImg/al4.png";
import al5 from "../../images/alumImg/al5.png";
import al6 from "../../images/alumImg/al6.png";
import al7 from "../../images/alumImg/al7.png";
import al8 from "../../images/alumImg/al8.png";
import al9 from "../../images/alumImg/al9.png";
import al10 from "../../images/alumImg/al10.png";
import al11 from "../../images/alumImg/al11.png";

export const alumniData = [
  {
    img: al1,
    Name: "Ms. Chandni",
    text: `Ms. Chandni alumni of ANM 2008 and a sponsored student of Deepalaya NGO. After e completion of  her senior secondary education went to USA for pursuing a short skill of one-year certification course in 'Nursing assistant from 2009 to 2010. After that she completed her B.Sc. in nursing degree from Holy Family Nursing College, New Delhi in 2017, she worked as a nursing officer in ICU in Holy Family Hospital and completed M.Sc. in nursing Jamia Hamdard University in 2018. `,
  },
  {
    img: al2,
    Name: "Ms. Rubi",
    text: `Ms. Rubi Hooda a GNM alumni 2011 and a girl from Haryana. She opted for GNM in 2007 and completed in 2011 and joined as a staff nurse in Jaipur Golden hospital Delhi, after that. She had been continuously working hard for Govt. defence and civil services. In 2013 she was selected in CRPF, and joined as an inspector. But sky is not a limit for her. Again in 2017, she was selected as an Assistant Commandant in BSF through UPSC, but didn't join due to her personal reason. Once more she appeared in UPPCS and was selected as an Income Tax and Revenue Inspector. Here also she couldn’t join due to her personal issues.Presently, she is trying for UPSC, UPPCS AND HPSC Exams through “Surajmal Foundation Group,” scholarship in Delhi.`,
  },
  {
    img: al3,
    Name: "Ms. Meena Sheron ",
    text: `Ms. Meena Sheron has started her journey as a house wife. With the grace of God, and blessings, she took her first step towards persuading ANM in SOS Nursing School 2006 and completed in 2008 with flying colours. But it didn't stopped her journey, she took admission in Sarvodaya Nursing Institute in 2008 in GNM and completed with good grades. Sky is not the limit for her again she took admission in KVM nursing College Rohtak in 2013 for Post Basic Nursing.She got a golden change to serve in SOS Nursing School in February 2015 in the post of nursing tutor `,
  },
  {
    img: al4,
    Name: "Ms. Shashi Kumari",
    text: `Ms. Shashi Kumari daughter of Mr. Mohan Pal ,ANM alumni of 2009, started her carrier working as an ANM at Anangpur sub centre. Presently she is working at PHC Anangpur, Faridabad with. `,
  },
  {
    img: al5,
    Name: "Ms. Manisha Hooda",
    text: `Ms. Manisha Hooda a GNM alumni 2009, started her carrier working as a staff nurse in RML hospital in Delhi ,a central Govt. hospital. Presently she is working in the post of ward sister in CCU.`,
  },
  {
    img: al6,
    Name: "Ms. Suruchi Mathur",
    text: `Ms. Suruchi Mathur daughter of Mr. Balraj Singh, completed her GNM from SOS Nursing School in the year 2010. She had been always a bright student in her academics and other co- curricular activities. She worked as a staff nurse in Maharaja Agrasen hospital in Delhi from 2009 to 2013. After that she moved to Fortis hospital, Delhi. In 2016 she got married and moved to Toronto, Ontario, Canada for her better carrier perspectives. `,
  },
  {
    img: al7,
    Name: "Ms. Pooja Verma",
    text: `Ms. Pooja Verma , GNM alumni of 2011 and a child from SOS CV Greenfields started her carrier working as a staff nurse in RML hospital in Delhi a central Govt. hospital. Presently she is working in the post of ward sister in ICU. `,
  },
  {
    img: al8,
    Name: "Ms. Binesh Kumari ",
    text: `Ms. Binesh Kumari daughter of Mr. Sohan Pal a GNM alumni 2013, started her carrier working as a staff nurse in Apollo hospital, Sarita vihar. Delhi. Presently working as e- doctor clinic in Max hospital, Saket. `,
  },
  {
    img: al9,
    Name: "Ms.Nitika Rawat",
    text: `
    Ms.Nitika Rawat daughter of Mr. Surender Rawat GNM alumni of 2015, started her carrier working as a staff nurse in Max hospital, Saket. Delhi. Presently she is working in the post of ward sister in cardiac OT.
     `,
  },
  {
    img: al10,
    Name: "Ms. Radha daughter",
    text: `Ms. Radha daughter of Mr. Bachchoo Singh alumni of GNM 2017. She started her carrier as a trainee nurse in Asian Institute of Medical Sciences, Faridabad. Presently she is posted in emergency department. `,
  },
  {
    img: al11,
    Name: "Ms. Binny Baisla",
    text: `Ms. Binny Baisla daughter of Mr. Bhrat Baisla alumni of GNM 2018. She started her carrier as a trainee nurse in Jaypee hospital, Noida. Presently she is posted in Neonatal ICU department in the post of staff nurse .`,
  },
];
