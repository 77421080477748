import React from "react";

import "./nurse.css";
import { Row, Col } from "react-bootstrap";

import {
  GneralNursingCourse,
  AuxiliaryCourse,
} from "../CourseOffered/Variable";

import Nursing from "../CourseOffered/Nurse Course/Nursing";
import Auxiliary from "../CourseOffered/Nurse Course/Auxiliary";

const Nursecard = () => {
  return (
    <>
      <section className="coursecard">
        <div style={{ marginBottom: "1.5rem" }}>
          <h2 className="myheading">Courses Offered</h2>

          <div className="coursecard d-flex">
            <Row className="d-flex align-items-start">
              <Col lg="4">
                <Nursing data={GneralNursingCourse} />
              </Col>

              <Col lg="4">
                <Auxiliary data={AuxiliaryCourse} />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nursecard;
