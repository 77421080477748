import React from "react";
import LandingImage from "../LandingImage";
import login from "../../images/covers/AlumniCover.jpeg";
import Sidebar from "../Sidebar/Sidebar";
import "../Home/Login.css";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import paTopIcon from "../../images/covers/pa.png";

function FeeStructure() {
  return (
    <div>
      <Navbar />
      <LandingImage cover={login} />
      <div className="contentmaincont">
        <Sidebar />
        <div>
          {/* Parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
              alt="Parent Icon"
            />
            Fee Structure
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <a
                href="./Fee Structure GNM course 2024-2025.pdf"
                download="Fee Structure GNM course 2024-2025.pdf"
              >
                <button className="apply-btn cursor mx-2">
                  Fee Structure GNM
                </button>
              </a>
            </div>
            <div>
              <a
                href="./Fee Structure ANM course 2024-2025.pdf"
                download="Fee Structure ANM course 2024-2025.pdf"
              >
                <button className="apply-btn cursor mx-2">
                  Fee Structure ANM
                </button>
              </a>
            </div>
          </div>
          {/* Students' voice */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FeeStructure;
