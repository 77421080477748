import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";

import "./faculty.css";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
const Faculty = () => {
  const [open, setOpen] = useState(false);
  const showData = (state) => {
    setOpen(!state);
  };
  const [open1, setOpen1] = useState(false);
  const showData1 = (state) => {
    setOpen1(!state);
  };

  console.log(open);
  return (
    <>
      <div className="contentmaincont">
        <Sidebar />

        <Container>
          <div class="mandatory_heading">
            <h2>Faculty</h2>
          </div>
          <section className="Table">
            <div className="niz">
              {/* <div className="subhead">Sos Nurisng School,Faridabad</div> */}
              <table className="table table-bordered  text-center">
                <thead className="mandatory_list">
                  <tr>
                    <th scope="col">S. No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Designation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Prof. Inderpreet Kaur</td>
                    <td>Principal</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>

                    <td>Ms. Sakshi</td>
                    <td>Assistant Prof.</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>

                    <td>Ms. Sweety</td>
                    <td>Senior Nursing Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>

                    <td>Ms. Neha Benjamin</td>
                    <td> Nurisng Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Ms. Priyanka Choudhary</td>
                    <td>Nurisng Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>Ms. Pinki Verma</td>
                    <td>Nurisng Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>Mr. Basruddin</td>
                    <td>Nurisng Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>Ms. Chinky</td>
                    <td>Nurisng Tutor</td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>Ms. Anu Chandila</td>
                    <td>Clinical Instructor</td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>Ms. Neeta</td>
                    <td>Clinical Instructor</td>
                  </tr>
                  <tr>
                    <th scope="row">11</th>
                    <td>Ms. Anjum</td>
                    <td>Clinical Instructor</td>
                  </tr>
                </tbody>
                {/* <div class="mandatory_table">
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">S. No</div>
                    <div class="mandatory_col col-lg-6">Name</div>
                    <div class="mandatory_col_last col-lg-5"> Designation </div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">1</div>
                    <div class="mandatory_col col-lg-6">Kumkum Ghosh</div>
                    <div class="mandatory_col_last col-lg-5">Principal</div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">3</div>
                    <div class="mandatory_col col-lg-6">Gunjan Kafaliya</div>
                    <div class="mandatory_col_last col-lg-5">Tutor</div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">4</div>
                    <div class="mandatory_col col-lg-6">Kajal Goyal</div>
                    <div class="mandatory_col_last col-lg-5">Tutor</div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">5</div>
                    <div class="mandatory_col col-lg-6">Tenzin Chhoying</div>
                    <div class="mandatory_col_last col-lg-5">Tutor</div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">6</div>
                    <div class="mandatory_col col-lg-6">Sakshi Tyagi</div>
                    <div class="mandatory_col_last col-lg-5">Lecturer</div>
                  </div>
                  <div class="mandatory_list row">
                    <div class="mandatory_col col-lg-1">7</div>
                    <div class="mandatory_col col-lg-6">Priya Shukla</div>
                    <div class="mandatory_col_last col-lg-5">Tutor</div>
                  </div>
                </div> */}
              </table>
            </div>
            {/* <div className="rai">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Raipur
              </div> */}

            {/* <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr.Amrish Singh</div>
                  <div class="mandatory_col_last col-lg-5">
                    Project Incharge
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Yoginder Dhiman</div>
                  <div class="mandatory_col_last col-lg-5">
                    Automobile Instructure
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className="bhakat">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Bhakatpara
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Ms. Jury Das</div>
                  <div class="mandatory_col_last col-lg-5">
                    Handloom Weaving Trainer
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="faridabad">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Faridabad
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. Vikram Chawla</div>
                  <div class="mandatory_col_last col-lg-5">
                    Adminstrative Head
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Rajesh Singh</div>
                  <div class="mandatory_col_last col-lg-5">Tally Trainer</div>
                </div>

                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Ms. Jyoti Bhadana</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="palla">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Palla
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. Manish Kumar</div>
                  <div class="mandatory_col_last col-lg-5">
                    Data Entry Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Adarsh Tiwari</div>
                  <div class="mandatory_col_last col-lg-5">Tally Trainer</div>
                </div>
              </div>
            </div> */}
            {/* <div className="">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Anangpur
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Ms. Lalita Rani</div>
                  <div class="mandatory_col_last col-lg-5">
                    Cutting and Tailoring Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Ms. Babli Bhadana</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="varanasi">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Varanasi
              </div>

              <div class="mandatory_table">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr. D.N. Rajput</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">2</div>
                  <div class="mandatory_col col-lg-6">Mr. Anuj Kumar Singh</div>
                  <div class="mandatory_col_last col-lg-5">Mobilizer</div>
                </div>
              </div>
            </div> */}
            {/* <div className="Bhopal">
              <div className="subhead">
                Hermann Gmeiner vocational Training Institute, Bhopal
              </div>

              <div class="mandatory_table  mb-5">
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">S. No</div>
                  <div class="mandatory_col col-lg-6">Name</div>
                  <div class="mandatory_col_last col-lg-5"> Designation </div>
                </div>
                <div class="mandatory_list row">
                  <div class="mandatory_col col-lg-1">1</div>
                  <div class="mandatory_col col-lg-6">Mr Nitin Sahu</div>
                  <div class="mandatory_col_last col-lg-5">
                    Computer Trainer
                  </div>
                </div>
              </div>
            </div> */}
          </section>
        </Container>
      </div>
    </>
  );
};

export default Faculty;
