import React from "react";
import "./inspire.css";
const Alumni = ({ data }) => {
  return (
    <div className="alumnis">
      <div className="firsttext">
        <h1 className="" style={{ marginLeft: "2.4rem" }}>
          Our Alumni
        </h1>

        {data.length !== 0 ? (
          <>
            {data.map((e, ind) => {
              if (ind % 2 === 0) {
                return (
                  <div className="ouralum" key={ind}>
                    <div
                      className="alu"
                      style={{
                        display: "flex",
                        gap: "1.7rem",
                        padding: "3rem 3rem",
                      }}
                    >
                      <div className="alumni-img">
                        <img src={e.img} class="content-img" alt="" />
                      </div>

                      <div>
                        <div className="alumni-name">{e.Name}</div>

                        <div className="alumni-text">{e.text}</div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="ouralum" key={ind}>
                    <div
                      className="alu"
                      style={{
                        display: "flex",
                        gap: "1.7rem",
                        padding: "3rem 3rem",
                      }}
                    >
                      <div>
                        <div className="alumni-name">{e.Name}</div>

                        <div className="alumni-text">{e.text}</div>
                      </div>

                      <div className="alumni-img">
                        <img src={e.img} class="content-img" alt="" />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </>
        ) : (
          <>"No Alumni"</>
        )}
      </div>
    </div>
  );
};

export default Alumni;
