import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./infra.css";
import InfraChild from "./infraChild";
const Infracard = (props) => {
  const [extend, setExtend] = useState(true);
  return (
    <>
      <div className="row ">
        {props.data.map((e, ind) => {
          return <InfraChild e={e} ind={ind} />;
        })}
      </div>
    </>
  );
};

export default Infracard;
