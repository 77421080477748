import React from "react";
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";
// import Home from "../../Home/Home";
import Sidebar from "../../Sidebar/Sidebar";

import { labImg } from "../infracarddata";
import { computerData } from "../infracarddata";
import PagesCard from "../PagesCard";
import LandingImage from "../../LandingImage";
import clinic from "../../../images/covers/clinic.jpg";
const ComputerLabs = () => {
  return (
    <>
      <Navbar />
      <LandingImage cover={clinic} />

      <div className="contentmaincont">
        <Sidebar />

        <div className="aboutus">
          {/* <div className="firstext">
                        <div className='mandatory_heading1'>{safetyData[0].title}</div>


                        <p style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            marginTop:"5px",
                            textAlign: "justify"
                        }}>{safetyData[0].desc}</p>


                    </div> */}
          {computerData.map((e, ind) => {
            return (
              <div className="firsttext">
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#009EE0",
                  }}
                >
                  {e.title}
                </div>

                <p>{e.desc}</p>
                <p>{e.desc2}</p>
                {/* <p>{e.desc3}</p> */}
              </div>
            );
          })}

          <PagesCard data={labImg} />
        </div>
      </div>

      <div className="mt-5" style={{ background: "#F8F9F9" }}>
        <Footer />
      </div>
    </>
  );
};

export default ComputerLabs;
