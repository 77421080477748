import React from "react";
import Navbar from "../navbar/Navbar";

import Faculty from "./Faculty";
import Footer from "../footer/Footer";
import LandingImage from "../LandingImage";
import faculty from "../../images/covers/faculty.jpeg";

const Facultyhome = () => {
  return (
    <>
      <Navbar />
      <LandingImage cover={faculty} />
      <Faculty />
      <Footer />
    </>
  );
};

export default Facultyhome;
