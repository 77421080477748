import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import useCollapse from "react-collapsed";
import { FaChalkboardTeacher } from "react-icons/fa";
import { GiArchiveResearch } from "react-icons/gi";
const Nursing = () => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse();
  return (
    <div>
      <Card
        style={{
          width: "350px",
          height: "auto",
          marginTop: "2rem",
          borderRadius: "20px",
          border: "6px solid rgba(244, 59, 0, 0.63)",
          boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <div
            style={{
              width: "340px",
              height: "3.9rem",
              marginLeft: "-17px",
              marginTop: "-17px",
              border: "1px solid rgba(244, 59, 0, 0.63)",
              backgroundColor: "rgba(244, 59, 0, 0.91)",
              color: "white",
              borderRadius: "10px",
            }}
          >
            <GiArchiveResearch
              style={{
                width: "8rem",
                height: "3rem",
                marginTop: "0.5rem",
                marginLeft: "7rem",
              }}
            />
          </div>
          <Card.Title
            style={{
              marginLeft: "5px",
              marginTop: "10px",
              fontSize: "1.3rem",
            }}
          >
            Nursing Research Activities
          </Card.Title>
          <Card.Text
            style={{
              fontSize: "1rem",
              marginLeft: "5px",
              marginTop: "0.9rem",
            }}
          >
            {/* <p>
              Diploma in General Nursing and Midwifery students who take up
              research project under the guidance of experience faculty as a
              part of their curriculum
              <p className="pp" {...getCollapseProps()}>
                during last 6 months of their course, students take up group
                projects and conduct rigorous nursing research.
              </p>
            </p> */}
            {isExpanded ? (
              <p>
                Diploma in General Nursing and Midwifery students who take up
                research project under the guidance of experience faculty as a
                part of their curriculum during last 6 months of their course,
                students take up group projects and conduct rigorous nursing
                research.
              </p>
            ) : (
              <p>
                Diploma in General Nursing and Midwifery students who take up
                research project under the guidance of experience faculty as a
                part of their curriculum
              </p>
            )}
          </Card.Text>
          <Button
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}
            style={{
              marginLeft: "60%",
              width: "7rem",
              height: "2.5rem",
              borderRaduis: "20px",
              border: "none",
              backgroundColor: "#081b24",
            }}
          >
            {isExpanded ? "Read Less" : "Read More"}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Nursing;
