import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import useCollapse from "react-collapsed";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { TiGroupOutline } from "react-icons/ti";

const Nurse = () => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse();
  return (
    <div>
      <Card
        style={{
          width: "350px",
          height: "auto",
          marginTop: "2rem",
          borderRadius: "20px",
          border: "6px solid #fa6487",
          boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <div
            style={{
              width: "340px",
              height: "4rem",
              marginLeft: "-17px",
              marginTop: "-17px",
              background: "#f53864",
              border: "1px solid #fa6487",
              color: "white",
              borderRadius: "10px",
            }}
          >
            <TiGroupOutline
              style={{
                width: "8rem",
                height: "3rem",
                marginTop: "0.5rem",
                marginLeft: "7rem",
              }}
            />
          </div>
          <Card.Title
            style={{
              marginLeft: "5px",
              marginTop: "10px",
              fontSize: "1.3rem",
            }}
          >
            Students Nurses Association
          </Card.Title>
          <Card.Text
            style={{
              fontSize: "1rem",
              marginLeft: "5px",
              marginTop: "1rem",
            }}
          >
            {/* <p>
              The Student Nurse Association (SNA) is a nation – wide
              organization. It was established in 1929 at the time of Annual
              <p {...getCollapseProps()}>
                conference of the Trained Nurses Association of Indian. At the
                unit level we have SNA advisor, vice president, secretary &amp;
                Treasures. Every year committee members are being selected. 
                SNA activities provide opportunity for every student to promote
                team spirit and encourage both professional and recreational
                meeting, games and sports.
              </p>
            </p> */}
            {isExpanded ? (
              <p>
                The Student Nurse Association (SNA) is a nation – wide
                organization. It was established in 1929 at the time of Annual
                conference of the Trained Nurses Association of Indian. At the
                unit level we have SNA advisor, vice president, secretary &amp;
                Treasures. Every year committee members are being selected. 
                SNA activities provide opportunity for every student to promote
                team spirit and encourage both professional and recreational
                meeting, games and sports.
              </p>
            ) : (
              <p>
                The Student Nurse Association (SNA) is a nation – wide
                organization. It was established in 1929 at the time
              </p>
            )}
          </Card.Text>
          <Button
            {...getToggleProps({
              onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}
            style={{
              marginLeft: "60%",
              width: "7rem",
              height: "2.5rem",
              borderRaduis: "20px",
              border: "none",
              backgroundColor: "#081b24",
            }}
            className="hover-overlay shadow-1-strong rounded"
          >
            {isExpanded ? "Read Less" : "Read More"}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Nurse;
