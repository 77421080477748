import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./photo.css";

const PhotoGallery = () => {
  let settings = {
    className: "slider variable-width",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    infinite: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const images1 = importAll(
    require.context(
      "../../images/photo-gallery",
      false,
      /\.(png|jpe?g|svg|jfif|JPE?G)$/
    )
  );
  return (
    <>
      <section className="my-5" style={{ background: "#F8F9F9" }}>
        <div
          className="m-lg-auto w-100 "
          style={{ marginTop: "20px" }}
          id="photo-gallery"
        >
          <div className="f-bold  text-center f-2 mx-4  photo_heading  ">
            Photo Gallery
          </div>
          <div className="pt-2 mt-md-0">
            <Slider className="my-3" {...settings}>
              {images1.map((image, index) => {
                console.log(image);
                return (
                  <div className="flex" key={index}>
                    <div className="slider-img-div">
                      <img className="sliderimage" src={image} alt="" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhotoGallery;
