import React from "react";
import LandingImage from "../LandingImage";
import login from "../../images/covers/AlumniCover.jpeg";
import Sidebar from "../Sidebar/Sidebar";
import "./Login.css";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { NavLink } from "react-router-dom";
import paTopIcon from "../../images/covers/pa.png";

function Login() {
  return (
    <div>
      <Navbar />
      <LandingImage cover={login} />
      <div className="contentmaincont">
        <Sidebar />
        <div>
          {/* s' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
            />
            's Area
          </div>
          <div className="d-flex justify-content-between">
            {/* <div>
              <NavLink to="/student-tc">
                <button className="apply-btn cursor mx-2">Student TC</button>
              </NavLink>
            </div> */}
            <div>
              <NavLink to="/new-registration">
                <button className="apply-btn cursor mx-2">
                  New Registration
                </button>
              </NavLink>
            </div>
            <div>
              <NavLink to="/existing-fees">
                <button className="apply-btn cursor mx-2">Fees Payment</button>
              </NavLink>
            </div>
          </div>

          {/*  */}

          {/* students' voice */}
        </div>
      </div>
      <div>
        {/* s' voice */}

        {/*  */}

        {/* students' voice */}
      </div>
      <Footer />
    </div>
  );
}

export default Login;
