import React, { useState } from "react";
import "./about1.css";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Container from "react-bootstrap/Container";
const About = () => {
  return (
    <>
      <div className="contentmaincont">
        <Sidebar />

        <Container>
          <div className="aboutus">
            <div className="firsttext">
              <div className="mandatory_heading1">
                About SOS Children's Villages
              </div>
              <p className="mt-2">
                SOS Nursing School, Faridabad is one of the pioneer institution
                in the field of nursing. Preparing nurses for last two and half
                decades. In 1998 when nursing was not well known as a profession
                and there were very few takers; SOS Nursing school was the only
                name at Faridabad and adjacent areas of Delhi for one and only
                Centre for nursing education. Keeping continuous quality
                improvement in process nursing school upgraded its courses in
                2004 from ANM to GNM. As Corona became pandemic the entire world
                nurses became angels who worked day and night for patients and
                simultaneously the profession got its due respect. Keeping all
                these in mind SOS nursing school is also striving to raise to
                the next level which is the Collegiate program within the near
                future. Our School is affiliated with the Indian Nursing Council
                as well as Haryana Nurses council and DMER – Directorate of
                Medical Education and Research, Haryana.
              </p>

              <p>
                SOS India has been providing care to orphan and abandoned
                children for 57 years across 32 children’s Villages in 22 states
                and union territories of India.
              </p>

              <p>
                The work of SOS Children’s Villages is based on children’s
                rights. The United Nations Convention on the Rights of the Child
                is a reference for any NGO working with and for children and
                therefore for SOS Children’s Villages of India.
              </p>
            </div>

            <div className="firsttext">
              <h4>
                As an independent, non-governmental, social-development
                organization
              </h4>
            </div>

            <div className="mainpoints">
              <div className="row mt-2 points">
                <div className="col-lg-12">
                  <div>
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="ll5">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="logo11"
                          >
                            <path d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path>
                          </svg>
                        </div>
                      </div>

                      <div className="col-lg-10">
                        <div className=" text-style5">Action</div>
                        <div className="skilll3">
                          We take actions for children in need of care and
                          Protection.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5 points">
                <div className="col-lg-12">
                  <div>
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="ll4">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 640 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="logo11"
                          >
                            <path d="M519.2 127.9l-47.6-47.6A56.252 56.252 0 0 0 432 64H205.2c-14.8 0-29.1 5.9-39.6 16.3L118 127.9H0v255.7h64c17.6 0 31.8-14.2 31.9-31.7h9.1l84.6 76.4c30.9 25.1 73.8 25.7 105.6 3.8 12.5 10.8 26 15.9 41.1 15.9 18.2 0 35.3-7.4 48.8-24 22.1 8.7 48.2 2.6 64-16.8l26.2-32.3c5.6-6.9 9.1-14.8 10.9-23h57.9c.1 17.5 14.4 31.7 31.9 31.7h64V127.9H519.2zM48 351.6c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16c0 8.9-7.2 16-16 16zm390-6.9l-26.1 32.2c-2.8 3.4-7.8 4-11.3 1.2l-23.9-19.4-30 36.5c-6 7.3-15 4.8-18 2.4l-36.8-31.5-15.6 19.2c-13.9 17.1-39.2 19.7-55.3 6.6l-97.3-88H96V175.8h41.9l61.7-61.6c2-.8 3.7-1.5 5.7-2.3H262l-38.7 35.5c-29.4 26.9-31.1 72.3-4.4 101.3 14.8 16.2 61.2 41.2 101.5 4.4l8.2-7.5 108.2 87.8c3.4 2.8 3.9 7.9 1.2 11.3zm106-40.8h-69.2c-2.3-2.8-4.9-5.4-7.7-7.7l-102.7-83.4 12.5-11.4c6.5-6 7-16.1 1-22.6L367 167.1c-6-6.5-16.1-6.9-22.6-1l-55.2 50.6c-9.5 8.7-25.7 9.4-34.6 0-9.3-9.9-8.5-25.1 1.2-33.9l65.6-60.1c7.4-6.8 17-10.5 27-10.5l83.7-.2c2.1 0 4.1.8 5.5 2.3l61.7 61.6H544v128zm48 47.7c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16c0 8.9-7.2 16-16 16z"></path>
                          </svg>
                        </div>
                      </div>

                      <div className="col-lg-10">
                        <div className=" text-style4">Community</div>
                        <div className="skilll3">
                          We respect varing religions and cultures, and we work
                          in communities where our mission can contribute to
                          development
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 points">
                <div className="col-lg-12">
                  <div>
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="ll3">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            className="logo11"
                          >
                            <path d="M439 32v165h18V32h-18zm-18 12.99L327.6 80l93.4 35V44.99zM165.9 103c-5 0-10.2 2.3-15.3 7-6.2 5.8-11.5 15.1-13.8 26.3-2.3 11.3-1 22 2.5 29.7 3.5 7.8 8.6 12.3 14.6 13.5 6 1.3 12.4-.9 18.7-6.6 6.1-5.8 11.5-15.1 13.8-26.4 2.2-11.3.9-22-2.5-29.7-3.5-7.8-8.6-12.2-14.6-13.5-1.1-.2-2.3-.3-3.4-.3zm-38.4 78.5c-3.4 1.2-6.9 2.5-10.7 4.1-24.85 15.7-42.2 31.2-59.84 55.7-11.19 15.5-11.74 42-12.58 61.5l20.8 9.2c.87-27.8.36-39.3 13.27-55.3 9.83-12.2 19.33-25 37.55-28.9 1.6 28.9-2.6 73.7-14 119.6 20.5 2.8 37.6-.7 57-6.3 50.7-25.3 74.1-3.8 109.3 45.7l20.5-32.1c-24.6-28.9-48.5-75.1-117.2-57.3 5-27.3 5.6-45.4 8.6-72.6.6-12 .8-23.9 1.1-35.7-8.9 6.8-19.9 10.4-31 8.1-9.5-2-17.3-7.9-22.8-15.7zm144.2 7.3c-18.2 17.8-22.2 31-50.2 38.4l-22.5-24c-.4 12.8-.8 25.9-1.9 39.2 9.5 8.7 19.2 15.7 22.7 14.6 31.3-9.4 40.3-20.3 61.4-41.9l-9.5-26.3zM409 215v96h-96v96h-96v78.1c102.3.2 167.8 1.1 270 1.8V215h-78zM140.7 363.9c-13.6 2.5-27.8 3.3-43.44.9-10.89 37.5-26.76 74.3-48.51 102.5l38.63 15.3c27.02-37.9 36.82-70.6 53.32-118.7z"></path>
                          </svg>
                        </div>
                      </div>

                      <div className="col-lg-10">
                        <div className=" text-style3">Growth Mindset</div>
                        <div className="skilll3">
                          We work in the spirit of the United Nation Convention
                          on the Rights of Child and we promote these rights
                          through our flagship programmes, namely the Family
                          Based Care and the Family Strengthening Programme
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="last-text">
              For over 5 decades we have continued to provide children without
              parental care or at the risk of losing it, a value chain of
              quality care services that goes beyond childcare alone, to
              ensuring comprehensive child development.
            </div>

            <div className="last-text mb-5">
              Our long-term customized care interventions such as, Family Like
              Care, Special Needs Childcare, Foster Care, Short Stay Homes,
              Family Strengthening, Kinship Care, Emergency Childcare, Education
              & Youth Skilling and are aimed at transforming lives and making
              children into self-reliant and contributing members of society.
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default About;
