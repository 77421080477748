import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import { BsFillPersonFill } from "react-icons/bs";

const Navbar = () => {
  const [show, setShow] = useState("mobile-hide");

  const handlesubmit = () => {
    console.log("Hello");

    if (show === "mobile-hide") {
      setShow("mobile-show");
    } else {
      setShow("mobile-hide");
    }
  };

  return (
    <>
      <section className="header">
        <NavLink to="/">
          <img className="logo1" src="./images/logo.svg" alt="" />
        </NavLink>

        <div>
          <ul className="navbar-list" id={show}>
            <li>
              <NavLink exact activeClassName="active" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <p style={{ marginBottom: "0" }}>About Us</p>
              <div className="sub-menu">
                <ul>
                  <li>
                    <NavLink to="/message">Message from Leadership</NavLink>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>
                    <a
                      href="https://www.soschildrensvillages.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Know More About SOS Children's Villages
                    </a>
                    <i className="fas fa-angle-right"></i>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p style={{ marginBottom: "0" }}>Courses</p>
              <div className="sub-menu1">
                <ul>
                  <li>
                    <NavLink to="/nursingcourses">Nursing Courses</NavLink>
                    <i className="fas fa-angle-right"></i>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p style={{ marginBottom: "0" }}>Activities</p>
              <div className="sub-menu">
                <ul>
                  <li>
                    <NavLink to="/academics">Academic Activities</NavLink>
                    <i className="fas fa-angle-right"></i>
                  </li>
                  <li>
                    <NavLink to="/extra">Extracurricular Activities</NavLink>
                    <i className="fas fa-angle-right"></i>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/pagecontact">
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" to="/fees">
                Fee Structure
              </NavLink>
            </li>
            <li>
              <p style={{ marginBottom: "0" }}>
                <BsFillPersonFill size={30} className="cursor mx-2" />
              </p>
              <div className="sub-menu">
                <ul>
                  <li>
                    <NavLink exact className="parent-login mx-2" to="/login">
                      Parent Login
                    </NavLink>
                  </li>
                  <li>
                    <NavLink exact className="student-login mx-2" to="/studentlogin">
                      Student Login
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <div id="close">
              <i onClick={handlesubmit} className="fas fa-times"></i>
            </div>
          </ul>
        </div>

        <div className="mobile">
          <i id="bar" onClick={handlesubmit} className="fas fa-outdent"></i>
        </div>
      </section>
    </>
  );
};

export default Navbar;
